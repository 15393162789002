import React from "react"
import Layout from "../components/Layout"
import imageGroup1 from "../images/photo-play3.jpg"
import imageGroup2 from "../images/photo-play4.jpg"

export default () => {
  return (
    <Layout>
      <main className="max-w-4xl flex-grow px-6 lg:px-0 md:mx-auto">
        <div className="flex flex-col md:flex-row md:items-stretch md:space-x-16 mb-12">
          <div className="md:w-1/2 flex items-center justify-center">
            <img
                className=""
                src={imageGroup1}
                alt="Group of Joe Athletics players"
              />
          </div>
          <div className="md:w-1/2">
            <h2 className="font-display font-bold text-6xl mb-4 text-cornflower-500">The Mission Statement</h2>
            <p className="text-xl font-light1 leading-relaxed">
              The ability to touch lives in a positive way and changing people for the better came naturally to Joe.  The Joe Little Foundation is dedicated to honoring him and “making sure” his amazing and unique essence continues and grows so all children can have an opportunity to follow their dreams with the same kind of support and passion that Joe Little lived his life.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-stretch md:space-x-16">
          <div className="md:w-1/2">
            <h2 className="font-display font-bold text-6xl mb-4 text-cornflower-500">The Vision</h2>
            <p className="text-xl font-light leading-relaxed">
              To have a positive and lasting change in communities through development of programs that will give school aged students a competitive advantage emotionally, educationally, and athletically using a whole-player philosophy.
            </p>
          </div>
          <div className="md:w-1/2 flex items-center justify-center">
            <img
                className=""
                src={imageGroup2}
                alt="Group of Joe Athletics players"
              />
          </div>
        </div>
      </main>
    </Layout>
  )
}
